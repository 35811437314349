import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/shared/seo"
import ContactBriefly from "../components/shared/contact-briefly/contact-briefly";
import BannerWithImg from "../components/shared/banner-with-img/banner-with-img";
import ServiceDesc from "../components/services/service-desc/service-desc";
import ServiceFeatures from "../components/services/service-features/service-features";
import ServiceTechnologies from "../components/services/service-technologies/service-technologies";

import websitesBannerImg from "../images/banners-img/websites-banner-img-tiny.jpg"
import websiteIcon from "../images/icons/website.svg"
import checkIcon from "../images/icons/check.svg"

import css3Logo from "../images/technologies/css3.svg"
import gatsbyLogo from "../images/technologies/gatsby.svg"
import gitLogo from "../images/technologies/git.svg"
import googleanalyticsLogo from "../images/technologies/googleanalytics.svg"
import graphqlLogo from "../images/technologies/graphql.svg"
import html5Logo from "../images/technologies/html5.svg"
import javascriptLogo from "../images/technologies/javascript.svg"
import letsencryptLogo from "../images/technologies/letsencrypt.svg"
import mariadbLogo from "../images/technologies/mariadb.svg"
import mysqlLogo from "../images/technologies/mysql.svg"
import nginxLogo from "../images/technologies/nginx.svg"
import npmLogo from "../images/technologies/npm.svg"
import phpLogo from "../images/technologies/php.svg"
import postgresqlLogo from "../images/technologies/postgresql.svg"
import sassLogo from "../images/technologies/sass.svg"
import webpackLogo from "../images/technologies/webpack.svg"
import wordpressLogo from "../images/technologies/wordpress.svg"

// import webappIcon from "../images/icons/web-app.svg"
// import mobileappIcon from "../images/icons/mobile-app.svg"
// import consultingIcon from "../images/icons/consulting.svg"

const features = [
	{ id: "cms", title: "Gestor de contenidos", desc: "Administración del contenido de su sitio de forma sencilla.", iconSrc: checkIcon},
	{ id: "seo", title: "SEO", desc: "Optimización para motores de búsqueda.", iconSrc: checkIcon},
	{ id: "responsive", title: "Responsive Design", desc: "Diseño adaptable a cualquier dispositivo.", iconSrc: checkIcon},
	{ id: "hosting", title: "Soporte y Hosting", desc: "Soporte y mantenimiento del alojamiento web.", iconSrc: checkIcon}
];

const technologies = [
	{ id: "css3", url:"", logoSrc: css3Logo},
	{ id: "gatsby", url:"", logoSrc: gatsbyLogo},
	{ id: "git", url:"", logoSrc: gitLogo},
	{ id: "googleanalytics", url:"", logoSrc: googleanalyticsLogo},
    { id: "graphql", url:"", logoSrc: graphqlLogo},
	{ id: "html5", url:"", logoSrc: html5Logo},
	{ id: "javascript", url:"", logoSrc: javascriptLogo},
	{ id: "letsencrypt", url:"", logoSrc: letsencryptLogo},
	{ id: "mariadb", url:"", logoSrc: mariadbLogo},
	{ id: "mysql", url:"", logoSrc: mysqlLogo},
	{ id: "nginx", url:"", logoSrc: nginxLogo},
	{ id: "npm", url:"", logoSrc: npmLogo},
	{ id: "php", url:"", logoSrc: phpLogo},
	{ id: "postgresql", url:"", logoSrc: postgresqlLogo},
	{ id: "sass", url:"", logoSrc: sassLogo},
	{ id: "webpack", url:"", logoSrc: webpackLogo},
	{ id: "wordpress", url:"", logoSrc: wordpressLogo}
    
];

const ServicesWebsitesPage = () => {

    return (
        <Layout>
            <SEO title="Desarrollo de páginas web" description="Comunique de manera efectiva lo que necesita con su página web a la medida. Desarrollo web Costa Rica." lang="es" path="paginas-web/" meta={[
			{
				property: `og:type`,
				content: `website`,
			},
			{
			    property: `og:image`,
			    content: 'https://res.cloudinary.com/spheredevs/image/upload/v1600464750/SphereDevs/websites-banner-img-tiny.jpg'
			},
			{
				property: `og:locale`,
				content: 'es_CR',
			},
			{
				name: `twitter:card`,
				content: `summary`,
			},
			{
				name: `twitter:title`,
				content: "Desarrollo de páginas web",
			},
			{
				name: `twitter:description`,
				content: "Comunique de manera efectiva lo que necesita con su página web a la medida. Desarrollo web Costa Rica.",
			},
			{
				name: `twitter:creator`,
				content: `@SphereDevs`
			},
			{
				name: `twitter:site`,
				content: `@SphereDevs`,
			}
		]}/>
            <BannerWithImg urlImage="https://res.cloudinary.com/spheredevs/image/upload/v1600464750/SphereDevs/websites-banner-img-tiny.jpg" description="Desarrollo de páginas web a la medida" />
            <ServiceDesc text="Si lo que busca es conectar con sus clientes, desarrollamos páginas web con herramientas que le permiten comunicar de manera efectiva lo que usted necesita y así sus clientes tengan la mejor experiencia al buscar sus productos y servicios de forma online." iconSrc={websiteIcon}/>
            <ServiceFeatures title="Todos nuestos desarrollos cuentan con:" elements={features} />
            <ServiceTechnologies title="Desarrolle sus proyectos con lo mejor de la industria" elements={technologies} />
            <ContactBriefly description="¿Tienes algo en mente? No esperes más!" contactUsAction="/contacto" contactUsLabel="Iniciar proyecto" />
        </Layout>
    )
}

export default ServicesWebsitesPage